@import '../../_styles/breakpoints';

.site-header {
  position: sticky;
  top: 0;
  z-index: 60;
}

@include breakpoint(tablet) {
  .ecl-site-header-core__action {
    align-items: flex-end;
  }
}
